import ebrelle1 from "../assets/eBrelle Homepage 2.JPG"
import ebrelle2 from "../assets/side_ebrelle.JPG"
import ebrelle3 from "../assets/eBrelle Homepage 3.JPG"
import ebrelle4 from "../assets/eBrelle Homepage 4.JPG"
import ebrelle5 from "../assets/side2_ebrelle.JPG"
import ebrelle6 from "../assets/eBrelle Homepage 6.JPG"
import ebrelle7 from "../assets/eBrelle Homepage 8.jpg"
import ebrelle8 from "../assets/eBrelle Homepage 5.jpg"

import "./Gallery.css";

export const Gallery = () => {
  return (
    <div className="gallery">
        <div>
            <h1>Gallery</h1>
        </div>
        <div className="img-gallery">
            <img src={ebrelle1} alt="picture ebrelle 1"/>
            <img src={ebrelle2} alt="picture ebrelle 2"/>
            <img src={ebrelle3} alt="picture ebrelle 3"/>
            <img src={ebrelle4} alt="picture ebrelle 4"/>
            <img src={ebrelle5} alt="picture ebrelle 5"/>
            <img src={ebrelle6} alt="picture ebrelle 5"/>
            <img src={ebrelle7} alt="picture ebrelle 5"/>
            <img src={ebrelle8} alt="picture ebrelle 5"/>
        </div>
    </div>
  )
}
