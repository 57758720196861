import { useRef } from "react";
import "./ContactData.css"
import staff1 from "../assets/Staff1.jpg";
import staff2 from "../assets/Staff2.jpg";
import staff3 from "../assets/Staff3.jpg";
import staff4 from "../assets/Staff4.jpg";
import staff5 from "../assets/Staff5.jpg";

import board1 from "../assets/zhariff_rm.png";
import board2 from "../assets/yani_rm.png";
import board3 from "../assets/Dr_Zul_rm.png";
import board4 from "../assets/cikgu_2_rm.png";
import board5 from "../assets/ustaz_adzam_rm.png";
import board6 from "../assets/ustaz_kyaireee_rm.png";
import board7 from "../assets/syed_rm.png";
import board8 from "../assets/rizan_rm.png";
import board9 from "../assets/zulhairi_rm.png";
import board10 from "../assets/zulhelmi_rm.png";

import map from "../assets/map.png";


import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

export const ContactData = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_yk1yqgl', 'template_t4o8xvh', form.current, 'mB3KJ-84ypzNOyiLu')
      .then((result) => {
          console.log(result.text);
          console.log("Message sent");
          toast.success('Message sent!')
      }, (error) => {
          console.log(error.text);
          toast.error('Message NOT sent!')
      });
  };

  
  return (
    <div className="contact-container">
        <br />
        <h6 className="meet">Meet our team !</h6>
        <div className="ceo-col">
          <div className="afifah">
              <img src={staff1} alt="" />
              <h2>Dr. Nor Afifah Shabani</h2>
              <h3>Executive Director</h3>
          </div>
          <div className="afifah">
              <img src={staff2} alt="" />
              <h2>Ts. Dr. Shaharil Mad Saad</h2>
              <h3>Technical Advisor</h3>
          </div>
        </div>
        <div className="ceo-col">
          <div className="faris">
              <img src={staff3} alt="" />
              <h2>Fariss Daniel Khairil Hariss</h2>
              <h3>Software Engineer</h3>
          </div>
          <div className="faris">
              <img src={staff4} alt="" />
              <h2>Al-Alif Aizat AlFaizal</h2>
              <h3>Software Engineer</h3>
          </div>
          <div className="faris">
              <img src={staff5} alt="" />
              <h2>Hasrul Hafizee Mohd Hasni</h2>
              <h3>Mechanical Engineer</h3>
          </div>
        </div>

        <h6 className="meet-board">Board of Advisors</h6>
        <div className="ceo-col2">
          <div className="zhariff">
              <img src={board1} alt="" />
              <h2>Dr. Khairul Zharif Zaharudin</h2>
              <h3>Strategic Management</h3>
          </div>
          <div className="zhariff">
              <img src={board2} alt="" />
              <h2>Dr. Nor Elliany Hawa Ibrahim</h2>
              <h3>Financial Management</h3>
          </div>
          <div className="zhariff">
              <img src={board3} alt="" />
              <h2>Dr. Zulkifli Mohd Yusop</h2>
              <h3>Electronic Expert</h3>
          </div>
          <div className="zhariff">
              <img src={board4} alt="" />
              <h2>Cikgu Zakaria Yahya</h2>
              <h3>Braille Quran Expert</h3>
          </div>
          <div className="zhariff">
              <img src={board5} alt="" />
              <h2>Dr. Mohd Nur Adzam Rasdi</h2>
              <h3>Quran for Special Needs & Quranic Orthography Expert</h3>
          </div>
          <div className="zhariff">
              <img src={board6} alt="" />
              <h2>PM. Dr. Haji Norakyairee Mohd Raus</h2>
              <h3>Braille Quran Expert</h3>
          </div>
          <div className="zhariff">
              <img src={board7} alt="" />
              <h2>Syed Azmi Alhabshi</h2>
              <h3>CSR Expert</h3>
          </div>
          <div className="zhariff">
              <img src={board8} alt="" />
              <h2>Rizan Hassan</h2>
              <h3>TVET Expert</h3>
          </div>
          <div className="zhariff">
              <img src={board9} alt="" />
              <h2>Muhamad Zulhairi Bin Mohd Nizam</h2>
              <h3>eBrelle Expert</h3>
          </div>
          <div className="zhariff">
              <img src={board10} alt="" />
              <h2>Muhamad Zulhelmi Bin Mohd Nizam</h2>
              <h3>eBrelle Expert</h3>
          </div>
        </div>
        

        <h6 className="meet-contact">Contact Us</h6>
        
        <br />
        <div className="quotation">
          <h7>For quotation or any inquiry</h7>
          <br />
          <h7>Whatapp us at: </h7>
          <br />
          <a href="https://api.whatsapp.com/send?phone=60182574520">+6018-2574520</a>
          <br />
          <img src={map} alt="" />
        </div>
        
        <div className="address-section">
          <h3>
            Address: <br />
            eBrelle Laptop for the Blind <br />
            No 28-2, Jalan Eco Majestic 3A/6B, <br />
            43500 Semenyih, Selangor.
          </h3>
          <h3>
            Working Hours: <br />
            Friday - Tuesday : 9:00 - 5:00 pm <br /><br />
            Closed : Wednesday & Thursday
          </h3>
        </div>
        
    </div>
  )
}
