import React from 'react'
import "./Post5.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/eBrelle Homepage 8.jpg";
import post1b from "../assets/ebrelle SEE Project 2.jpg";
import post1c from "../assets/ebrelle SEE Project 3.jpg";
import sign from "../assets/afifah_sign.png"


function Post5() {
  return (
    <div className='post5-section'>
      <div className='comment5-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>14 October 2024  (2 min read)</p></h4>
      </div> 
      <div className='picture5-post'>
          <img src={post1a} alt="picture of ebrelle panel"></img>
      </div>
      <div className='text5-section'>
        <h3>SEE Project: Strengthening Equal Education for the Blind through eBrelle Technology</h3>
        <h5>
          The <span>SEE Project</span> is committed to empower visually impaired students across 
          Malaysia by providing them with access to cutting-edge <span>eBrelle</span> technology. 
          With around <span>2,700 visually impaired students in schools and 300 in universities</span>, 
          many of these students lack the financial means to obtain the essential tools 
          they need for their education.
        </h5>
      </div>
      <div className='picture5-post'>
          <img src={post1b} alt="picture with children PES"></img>
      </div>
      <div className='text5-section'>
        <h5>
          The SEE Project invites Malaysian companies to contribute their <span>Corporate 
          Social Responsibility (CSR)</span> budgets toward providing <span>eBrelle</span> devices 
          to these students. By donating to this cause, corporations can play a 
          vital role in ensuring that these young learners—both in schools and 
          universities—are not left behind in their educational journeys due to 
          financial barriers.
        </h5>
      </div>
      <div className='picture5-post'>
          <img src={post1c} alt="teacher teach student using ebrelle"></img>
      </div>
      <div className='text5-section'>
        <h5>
          The SEE Project invites Malaysian companies to contribute their Corporate 
          Social Responsibility (CSR) budgets toward providing eBrelle devices 
          to these students. By donating to this cause, corporations can play a 
          vital role in ensuring that these young learners—both in schools and 
          universities—are not left behind in their educational journeys due to 
          financial barriers.
        </h5>
        <h5>
          Aligned with <span>Sustainable Development Goals (SDG) 4</span> for Quality Education 
          and <span>SDG 10</span> for Reduced Inequality, the SEE Project seeks to promote 
          <span> equitable access to education</span> for all. Through your CSR contributions, 
          you can help transform the lives of visually impaired students, equipping 
          them with the tools they need to succeed academically and professionally.
        </h5>
      </div>

      
      <div className='text2-section'>
        <h5>
          Join us in making a lasting difference by supporting <span>equal education 
          opportunities</span> for all students in Malaysia.
        <br />
        <br />
        #HelpThemSEE
        <br />
        <br />
        Interested to contribute? Leave us a message at our email address <i>ebrelle.laptop@gmail.com</i>
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border5-comment'>
        <h6>106 views &emsp; 0 comments</h6>
      </div>
    </div>
  )
}

export default Post5