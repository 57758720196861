import { DestinationData } from "./DestinationData"
import "./DestinationStyles.css"

import ebrelle_brochure from "../assets/eBrelle_Brochure.pdf"

import ebrelle_wan_fadil from "../assets/eBrelle Typing and Storing 1.jpg"
import ebrelle_dr_sam from "../assets/eBrelle Typing and Storing 2.jpg"

import ebrelle_firdaus_pertis from "../assets/Reading_1.jpg"
import ebrelle_firash from "../assets/ebrelle_reading_material_3.jpeg"

import putra1 from "../assets/putra1.jpeg"
import ebrelle_dr_shaharil from "../assets/eBrelle 2.JPG"
// import ebrelle_dr_shaharil from "../assets/ebrelle_hold.jpeg"
import ebrelle_video from "../assets/Video Promo eBrelle.mp4"

import icon2 from "../assets/2.jpg"
import icon3 from "../assets/3.jpg"
import icon4 from "../assets/4.jpg"
import icon5 from "../assets/5.jpg"
import icon6 from "../assets/6.jpg"

import icon7 from "../assets/7.jpg"
import icon8 from "../assets/8.jpg"
import icon9 from "../assets/9.jpg"
import icon10 from "../assets/10.jpg"
import icon11 from "../assets/11.jpg"

import icon12 from "../assets/12.jpg"
import icon13 from "../assets/13.jpg"
import icon14 from "../assets/14.jpg"
import icon15 from "../assets/15.jpg"
import icon16 from "../assets/16.jpg"
import icon17 from "../assets/17.jpg"
import icon18 from "../assets/18.jpg"
import icon19 from "../assets/19.jpg"
import icon20 from "../assets/20.jpg"

import gambar_ebrelle from "../assets/eBrelle_Homepage_8.jpg"

export const Destinations = () => {
  return (
    <div className="destination">
        <div className="first-section">
                <video src={ebrelle_video} controls autoplay alt={ebrelle_video}></video>
                <div className="text-ebrelle">
                  <h1>eBrelle</h1>
                  <p>Laptop for the Blind is an essential tool for the Blind and visually impaired people, especially students and working adults. It replaces the Braille type-writer machine and functions as a laptop </p>
                </div>
                <div className="down-btn">
                    <a
                      href={ebrelle_brochure}
                      download="eBrelle Brochure"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="ebrelle brochure"
                    >
                    Download Brochure
                    </a>
                </div>
                <div className="icon-section">
                  <div className="icon-body">
                      <div className="icon-title">
                        <h1>Auto Braille converter for:</h1>
                        <img src={icon2} alt="icon pdf file"/>
                        <img src={icon3} alt="icon word file"/>
                        <img src={icon4} alt="icon ppt file"/>
                        <img src={icon5} alt="icon txt file"/>
                        <img src={icon6} alt="icon hand and braille"/> 
                      </div>
                      <div className="icon-title">
                        <h1>User-friendly for all ages</h1>
                        <img src={icon7} alt="icon children read braille"/>
                        <img src={icon8} alt="icon student read braille"/>
                        <img src={icon9} alt="icon older read braille"/>
                      </div>
                      <div className="icon-title">
                        <h1>Typing and storing information</h1>
                        <img src={icon10} alt="icon keyboard typing"/>
                        <img src={icon11} alt="icon sd card"/>
                        <img src={icon12} alt="icon raspberry pi"/>
                        <img src={icon13} alt="icon pendrive"/>
                      </div>
                      <div className="icon-title">
                        <h1>Connectivity</h1>
                        <img src={icon14} alt="icon google meet"/>
                        <img src={icon15} alt="icon bluetooth"/>
                        <img src={icon16} alt="icon wifi"/>
                        <img src={icon17} alt="icon printer"/>
                      </div>
                      <div className="icon-title">
                        <h1>Power Supply</h1>
                        <img src={icon18} alt="icon battery fast charging"/>
                        <img src={icon19} alt="icon 4 hour"/>
                        <img src={icon20} alt="icon type c"/>
                      </div>
                  </div>
                  <div className="gambar-ebrelle">
                    <img src={gambar_ebrelle} alt="gamber ebrelle"/>
                  </div>
                </div>
        </div>
        
        <DestinationData
            heading = "Typing and Storing Information"
            text = "As a laptop, it can be used to type and store information in its internal 32G memory, or in your own hard disk. It is special because unlike the Braille type-writer machine it is very quiet and is chargeable using power-bank. Therefore, you can use it in the classroom without interfering other students."
            img1 = {ebrelle_wan_fadil}
            img2 = {ebrelle_dr_sam}
            className = "first-des"
        />
       
        <DestinationData
            heading = "Access to Digital Reading Materials"
            text = "Our eBrelle is very unique because it is the only electronic Braille panel that allows you to read in Braille directly from Word, PowerPoints and PDF documents in addition to your regular .txt documents. Now you have limitless access to reading materials that are saved in digital format. Happy reading!!"
            img1 = {ebrelle_firdaus_pertis}
            img2 = {ebrelle_firash}
            className = "first-des-reverse"
        />

        <DestinationData
            heading = "Learn and Read Al-Quran"
            text = "The most important feature of eBrelle is that it comes in complete package for you to learn and read Al-Quran in Braille format. It has voice guidance which helps you to practise on your own. Plus, it also has Iqra' module for beginners and Al-Quran translation (in Malay). You don't need to wait for your teacher anymore. You can start studying and revising your Quran recitation on your own."
            img1 = {putra1}
            img2 = {ebrelle_dr_shaharil}
            className = "first-des"
        />
    
    </div>
  )
}
