import NavbarAdvance from '../components/NavbarAdvance'
import { Footer } from '../components/Footer'
import { SEEPost } from '../components/SEEPost'

function SeeProject() {
  return (
    <div>
        <NavbarAdvance/>
        <SEEPost/>
        <Footer/>
    </div>
  )
}

export default SeeProject