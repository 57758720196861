import React from 'react'
import "./Post9.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/eBrelle CSR 2.jpg";
import post1b from "../assets/eBrelle CSR 3.jpg";
import post1c from "../assets/eBrelle CSR 4.jpg";
import sign from "../assets/afifah_sign.png"


function Post9() {
  return (
    <div className='post1-section'>
      <div className='comment-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>27 October 2024  (7 min read)</p></h4>
      </div> 
      <div className='text-section'>
        <h3>Corporate Social Responsibility (CSR) : <br /> Transforming the Future of Visually Impaired Children in Malaysia Through eBrelle Technology</h3>
        <h5>
          The SEE. Project is aimed at Strengthening Equal Education for the Blind 
          initiative through eBrelle Technology. Using eBrelle, an innovative 
          Braille-based device, allows visually impaired students to access digital 
          content in Braille, thereby levelling the playing field in education 
          for 2,700 visually impaired schoolchildren and 300 university students in Malaysia..
        </h5>
        <h5>
          We would like to invite all companies, through Corporate Social Responsibility (CSR) 
          efforts, to contribute to this project and together we can transform the future of 
          visually impaired children in Malaysia. By contributing to this SEE. Project, YOU can 
          directly contribute to creating a more inclusive and equitable education system for 
          these children, offering them tools to access knowledge and grow in ways that were 
          previously unimaginable.
        </h5>
        <h5>
          The impact of corporate donations for this SEE. Project is very profound because 
          many of these visually impaired children are unable to afford such essential devices. 
          By enabling access to this life-changing technology, companies are ensuring that these 
          students can actively engage in education, develop crucial skills, and realize their 
          full potential.
        </h5>
        <div className='picture9-section'>
          <img src={post1a} alt="usim at pusat sains negara"></img>
          <img src={post1b} alt="usim at pusat sains negara"></img>
          <img src={post1c} alt="usim at pusat sains negara"></img>
          <h6>Among the recipients of eBrelle from CSR and Zakat budget in 2023 and 2024</h6>
        </div>
        <h5>
          We would like to build a better future for these visually impaired 
          children: no longer depending on welfare or working low level income 
          jobs. We want these children to be independent economically by joining 
          middle-income or high-level-income jobs such as teachers, lawyers, 
          lecturers or even programmers. The current technology used in schools are 
          limiting them from achieving their full potential. Hopefully, by using 
          eBrelle technology, we can help them achieve their full potential and 
          contributing back to the nation.
        </h5>
        <h5>
          The United Nations Sustainable Development Goals (SDGs) emphasize the 
          importance of quality education and reducing inequalities. Through the 
          SEE Project, companies are not just making charitable donations, they 
          are aligning themselves with key global objectives:
        </h5>
        <h5>
          1. SDG 4: Quality Education – This goal calls for inclusive and equitable 
          quality education for all. By supporting eBrelle technology, companies help 
          ensure that visually impaired students can access the same educational 
          opportunities as their peers. eBrelle empowers these students to read, learn, 
          and participate in the digital age, which is essential for their intellectual 
          development and future employment prospects. The project aligns with SDG 4 by 
          providing tools that directly support lifelong learning and inclusive education 
          for disabled individuals.
        </h5>
        <h5>
          2. SDG 10: Reduced Inequalities – This goal focuses on reducing inequalities 
          within and among countries. In Malaysia, visually impaired students often face 
          barriers that prevent them from accessing the same educational resources as others, 
          leading to widened disparities. By investing in the eBrelle project, companies 
          contribute to narrowing this gap and ensuring that visually impaired students 
          are not left behind. This effort fosters a more inclusive society where everyone, 
          regardless of their physical abilities, has the opportunity to succeed.
        </h5>
        <h5><i> Benefits for Companies: A Meaningful CSR Strategy</i></h5>
        <h5>
          Contributing to the SEE Project is not just an act of philanthropy; 
          it offers tangible benefits to companies that align their CSR strategies 
          with this cause:
        </h5>
        <h5>
          1. Enhanced Corporate Image and Reputation – Companies that invest in 
          impactful projects like eBrelle build a strong reputation as socially 
          responsible and community-focused organizations. Supporting the education 
          of visually impaired students is a meaningful way to demonstrate corporate 
          values of empathy, inclusivity, and equity. This can strengthen brand 
          loyalty and enhance the company’s public image.
          <br /> 
          <br />
          2. Employee Engagement and Morale – CSR initiatives like the SEE Project give 
          employees a sense of pride in their company. Knowing that they are part of 
          an organization that contributes to social good can boost morale and foster 
          a more positive workplace culture. Employees are more likely to feel connected 
          to a company that takes meaningful action to support those in need.
          <br />
          <br />
          3. Corporate-Community Relations – Businesses that actively support social causes 
          often build stronger ties with the communities they operate in. By addressing 
          local challenges, such as the lack of resources for visually impaired students, 
          companies foster goodwill and create a more loyal customer base within the community. 
          Additionally, positive corporate-community relationships can lead to better business 
          opportunities and partnerships.
          <br />
          <br />
          4. Long-term Social Impact – By contributing to this project, companies are 
          directly influencing the long-term social fabric of Malaysia. Educating and 
          empowering visually impaired students ensures that they can grow into capable 
          adults who contribute meaningfully to society and the workforce. The long-term 
          impact of such investments is a more inclusive and productive society, where diverse 
          talents and abilities are embraced.

        </h5>
        <h5><i>A Collective Step Towards a Brighter Future</i></h5>
        <h5>
          The SEE Project is a powerful reminder of how technology, education, and 
          corporate responsibility can converge to create lasting change. Companies 
          that choose to support this cause are not only providing immediate benefits to 
          visually impaired students, but they are also helping to shape the future of 
          Malaysia by investing in a more inclusive, equal society. By aligning CSR efforts 
          with SDG 4 and SDG 10, businesses can leave a lasting legacy—one that promotes 
          quality education for all and reduces inequalities, ensuring that every child 
          has the opportunity to succeed, regardless of their physical challenges.
        </h5>
        
      </div>
      
      <div className='text2-section'>
        <h5>
          In a world where businesses are expected to contribute positively to 
          society, the SEE Project presents a clear path to making a real difference, 
          improving lives, and shaping a better, more inclusive future. Join us TODAY to 
          make a difference in these visually impaired students for a brighter future.
        <br />
        <br />
        Contact us at <i>ebrelle.laptop@gmail.com</i> or call us at <i>+60182574520 </i>for more information
        <br />
        <br />
        #HelpThemSEE
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border-comment'>
        <h6>106 views &emsp; 0 comments</h6>
      </div>
    </div>
  )
}

export default Post9