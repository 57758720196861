import React from 'react'
import "./Post6.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/Revolutionary 1.jpg";
import post1b from "../assets/Newsletter 7.jpg";
import post1c from "../assets/Newsletter 8.jpg";
import sign from "../assets/afifah_sign.png"


function Post6() {
  return (
    <div className='post1-section'>
      <div className='comment-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>17 October 2024  (2 min read)</p></h4>
      </div> 
      <div className='text-section'>
        <h3>What is eBrelle?</h3>
        <h5>
          eBrelle, a cutting-edge innovation, offers an accessible digital solution 
          for the blind and visually impaired. As a unique Braille laptop, it is 
          designed to simplify reading, learning, and note-taking in Braille, 
          making it an invaluable resource for those who rely on tactile communication. 
          The eBrelle laptop combines advanced technology with user-centric features, 
          making digital accessibility seamless and effective. Here’s a closer look at 
          some of its standout features.
        </h5>

        <div className='picture4-section'>
          <img src={post1a} alt="picture ebrelle features"></img>
        </div>
        <h5><i>Automatic Text-to-Braille Conversion</i></h5>
        <h5>
          One of eBrelle’s core strengths is its ability to automatically convert 
          various digital text formats, including PDF, Word documents, and 
          PowerPoint presentations, into Braille. This feature opens a world of 
          possibilities for blind and visually impaired users, providing instant 
          access to a wide range of written materials, from educational content to 
          personal documents. By eliminating the need for traditional Braille 
          transcription, eBrelle empowers users to independently explore an expansive 
          library of digital resources with ease.
        </h5>
        <h5><i>A Special Braille Computer with Note-Taking Capabilities</i></h5>
        <h5>
          eBrelle replaces the need for traditional Braille machines with a sophisticated, 
          portable Braille computer that includes easy-to-use note-taking capabilities. 
          The device allows users to write, save, and organize notes in Braille, supporting 
          everything from quick memos to longer documents. With 64GB of internal storage, 
          eBrelle provides ample space to store written content, making it ideal for students, 
          professionals, or anyone who wants a convenient, reliable way to keep track of their 
          thoughts and information.
        </h5>
        <h5><i>User-Friendly Design and Accessibility</i></h5>
      </div>
      <div className='text2-section'>
        <h5>
          Designed with accessibility in mind, eBrelle features a tactile interface that 
          is easy to navigate, providing a seamless experience for the blind and visually 
          impaired. Whether reading, learning, or taking notes, eBrelle combines the 
          familiarity of Braille with modern technology to make everyday tasks more accessible. 
          By providing essential tools for education, religious study, and personal organization 
          in a single device, eBrelle is a versatile companion for individuals of all ages.
        <br />
        <br />
        #HelpThemSEE
        <br />
        <br />
        Interested to contribute? Leave us a message at our email address <i>ebrelle.laptop@gmail.com</i>
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border6-comment'>
        <h6>56 views &emsp; 0 comments</h6>
      </div>
    </div>
  )
}

export default Post6