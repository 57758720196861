import React from 'react'
import "./Post4.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/Newsletter 9.jpeg";
import post1b from "../assets/Newsletter 7.jpg";
import post1c from "../assets/Newsletter 8.jpg";
import sign from "../assets/afifah_sign.png"


function Post4() {
  return (
    <div className='post1-section'>
      <div className='comment-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>11 October 2024  (2 min read)</p></h4>
      </div> 
      <div className='picture4a-section'>
          <img src={post1a} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text-section'>
        <h3>eBrelle Laptop for the Blind: A New Exhibition in Pusat Sains Negara</h3>
        <h5>
            Pusat Sains Negara recently hosted a significant event where the Vice Chancellor 
            of Universiti Sains Islam Malaysia (USIM) officially handed over the eBrelle Laptop 
            for the Blind to be part of the science center’s upcoming exhibition. The handover 
            ceremony was witnessed by Dato' Seri Dr. Wan Azizah Wan Ismail, wife of Prime Minister 
            Dato' Seri Anwar Ibrahim.
        </h5>
        <h5>
            eBrelle, an innovative device designed to assist visually impaired individuals, 
            represents a key step in making educational resources more accessible to the 
            blind community. The laptop, developed through research and collaboration with 
            the local tech community, converts digital text into Braille, enhancing 
            opportunities for learning and personal development for those with vision loss.
        </h5>
        <h5>
            During the handover, Dato' Seri Dr. Wan Azizah expressed her admiration for the 
            project, emphasizing the importance of inclusivity in education and the need for 
            innovative solutions to bridge gaps for people with disabilities. She highlighted 
            how devices like eBrelle can open new doors for the blind community, empowering 
            them to engage with education and technology on a new level.
        </h5>
        <h5>
            The Pusat Sains Negara will soon feature eBrelle as part of its permanent exhibition, 
            allowing visitors to explore how technology can change lives and foster greater 
            inclusivity. This addition underscores the center’s commitment to showcasing 
            groundbreaking advancements in science and technology that benefit all sectors of 
            society, including people with disabilities.
        </h5>
      </div>
      
      <div className='text2-section'>
        <h5>
            The exhibition is expected to serve as an educational platform, not just for those 
            directly affected by vision impairment but also for the broader public, demonstrating 
            the powerful role technology plays in overcoming barriers.
        <br />
        <br />
        #HelpThemSEE
        <br />
        <br />
        Interested to contribute? Leave us a message at our email address <i>ebrelle.laptop@gmail.com</i>
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border4-comment'>
        <h6>106 views &emsp; 0 comments</h6>
      </div>
    </div>
  )
}

export default Post4