import React from 'react'
import "./Post2.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/Newsletter 3.jpeg";
import post1b from "../assets/Newsletter 4.jpeg";
import post1c from "../assets/Newsletter 5.png";
import sign from "../assets/afifah_sign.png"

function Post2() {
  return (
    <div className='post1-section'>
      <div className='comment-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>5 October 2024  (2 min read)</p></h4>
      </div> 
      <div className='picture2a-section'>
          <img src={post1a} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text-section'>
        <h3>Expo TVET@KKDW 2024: Showcasing eBrelle Innovation at MITC Melaka</h3>
        <h5>
            Melaka, 2024 – The TVET@KKDW 2024 Expo, held at the Melaka International Trade Centre (MITC), garnered significant attention for its focus on empowering people with disabilities through innovative technologies. The event was graced by Deputy Prime Minister Dato' Seri Dr. Ahmad Zahid Hamidi, who took the time to explore the latest innovations and exhibitions in the field of Technical and Vocational Education and Training (TVET).
            One of the standout products displayed was eBrelle, a groundbreaking device designed to assist the blind and visually impaired in reading and learning Braille electronically. This technology was developed to enhance access to education and vocational training for individuals with visual impairments, aligning with TVET's goal of being inclusive for all members of society.
        </h5>
      </div>
      <div className='picture2a-section'>
          <img src={post1b} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text-section'>
        <h5>
            Dato' Seri Ahmad Zahid Hamidi, who warmly interacted with participants, 
            was particularly interested in a blind student, Amir, who confidently demonstrated how eBrelle functions. Amir showcased how the device simplifies the Braille learning process and accelerates reading. Innovations like eBrelle highlight how TVET can help people with disabilities become more independent and competitive in both education and employment.
        </h5>
      </div>
      <div className='picture2a-section'>
          <img src={post1c} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text2-section'>
        <h5>
            The expo, which featured a variety of other TVET-related innovations, underscored the critical role of technology in enhancing skills and creating career opportunities for all, regardless of physical challenges. Dato' Seri Ahmad Zahid Hamidi also praised TVET's efforts in empowering individuals with disabilities through cutting-edge technologies, emphasizing the nation's need for a highly skilled and inclusive workforce.
            With eBrelle taking center stage at the TVET Expo, visitors and VIPs alike were given a clear vision of how innovation can transform the lives of people with disabilities, particularly in providing them with tools for greater independence and success.

        <br />
        <br />
        #HelpThemSEE
        <br />
        <br />
        Interested to contribute? Leave us a message at our email address <i>ebrelle.laptop@gmail.com</i>
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border2-comment'>
        <h6>90 views &emsp; 0 comments</h6>
      </div>
    </div>
  )
}

export default Post2