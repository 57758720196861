import React from 'react'
import "./Post3.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/user20.jpg";
import post1b from "../assets/Newsletter 7.jpg";
import post1c from "../assets/Newsletter 8.jpg";
import sign from "../assets/afifah_sign.png"


function Post3() {
  return (
    <div className='post1-section'>
      <div className='comment-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>5 October 2024  (2 min read)</p></h4>
      </div> 
      <div className='picture3a-section'>
          <img src={post1a} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text-section'>
        <h3>Event Overview: Al-Quran eBrelle Review Part 2</h3>
        <h5>
        The second installment of the "Al-Quran eBrelle Review" workshop 
        was held at the Everly Hotel in Putrajaya. This three-day, 
        two-night event took place from September 14-16, 2024, 
        and was organized by Edote Sdn Bhd. The workshop is part of an 
        initiative aimed at empowering blind Quran teachers by providing 
        them with accessible technology tools to enhance their teaching and reading of the Quran.
        </h5>
        <h5><i>Participants and Structure</i></h5>
        <h5>
        The event hosted six visually impaired Quran teachers, each accompanied 
        by a sighted companion. These pairs worked together to deepen their 
        understanding and mastery of the eBrelle, a specialized laptop designed 
        specifically for the blind. The eBrelle device enables visually impaired 
        individuals to read and engage with the Quran using Braille technology, 
        offering a breakthrough in accessibility for those dedicated to teaching 
        and studying the Quran.
        </h5>
        
      </div>
      <div className='picture3a-section'>
          <img src={post1b} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text-section'>
        <h5>
        The Part 1 session of this workshop series was initially conducted in 
        March 2023, and the continuation reflects an ongoing commitment to refining 
        the use and functionality of the eBrelle device based on user feedback and 
        experiences. The trainers and facilitators from Edote Sdn Bhd provided 
        technical support, guidance, and hands-on practice sessions to ensure each 
        participant could effectively use the eBrelle.
        </h5>
        <h5><i>Highlights and Impact</i></h5>
        <h5>
        The participants, with the assistance of their companions, engaged in interactive 
        training sessions focusing on the device's advanced features, including its 
        Braille-reading capabilities and Quran-specific functions. Additionally, 
        the workshop aimed to foster a sense of community among visually impaired educators, 
        encouraging them to share their insights and experiences with the eBrelle.
        </h5>
      </div>
      <div className='picture3a-section'>
          <img src={post1c} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text-section'>
        <h5>
        The atmosphere was warm and collaborative, as illustrated by the group's photo, 
        which captures the enthusiasm and sense of accomplishment shared by all involved. 
        The organizers and participants alike celebrated the progress made in utilizing 
        this innovative device to make the Quran accessible to the visually impaired.
        </h5>
        <h5><i>Looking Ahead</i></h5>
        <h5>
        Edote Sdn Bhd plans to continue enhancing the eBrelle platform, addressing any 
        challenges faced by users, and expanding its reach. By enabling visually impaired 
        teachers to independently read and teach the Quran, this initiative not only 
        empowers the individuals but also contributes to a more inclusive society.
        </h5>
      </div>
      <div className='text2-section'>
        <h5>
        The Al-Quran eBrelle Review series signifies an important step toward accessible 
        technology for the visually impaired and highlights the potential for technology 
        to bridge gaps in education and religious study.
        <br />
        <br />
        #HelpThemSEE
        <br />
        <br />
        Interested to contribute? Leave us a message at our email address <i>ebrelle.laptop@gmail.com</i>
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border3-comment'>
        <h6>90 views &emsp; 0 comments</h6>
      </div>
    </div>
  )
}

export default Post3