import React from 'react'
import "./Post1.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/usim_psn.jpg";
import post1b from "../assets/Newsletter 2.jpg";
import sign from "../assets/afifah_sign.png"

function Post1() {
  return (
    
    <div className='post1-section'>
      <div className='comment-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>2 October 2024  (6 min read)</p></h4>
      </div> 
      <div className='picture1a-section'>
          <img src={post1a} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text-section'>
        <h3>Hari Sains dan OKU: A Celebration of Innovation and Inclusivity at Pusat Sains Negara</h3>
        <h5>The vibrant halls of Pusat Sains Negara were filled with excitement 
          and a spirit of inclusivity during the Hari Sains dan OKU event, where 
          science and technology were celebrated with a special focus on innovations
           for people with disabilities. The event brought together members of the 
           disabled community, students, educators, and prominent figures to witness 
           the groundbreaking technologies aimed at empowering differently abled 
           individuals. One of the highlights of the event was the presence of Dato' Seri Dr. Wan Azizah Wan Ismail, former Deputy Prime Minister of Malaysia and an advocate for social justice and equality. Her involvement in this event underscored the importance of empowering people with disabilities through scientific advancements. Dato' Seri Dr. Wan Azizah engaged with attendees, taking a keen interest in the various exhibits and innovations on display, including technologies developed by and for the blind community.
        </h5>
      </div>
      <div className='picture1b-section'>
          <img src={post1b} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text2-section'>
        <h5>
        eBrelle Laptop for the Blind an innovative technology designed to assist blind individuals in reading Braille electronically, captured significant attention during the event. Developed by local engineers from Edote Sdn Bhd, the device aims to enhance accessibility and ease of learning for visually impaired students. It was proudly demonstrated by a blind student, showcasing the potential of eBrelle to revolutionize Braille learning and accessibility. The student's active involvement symbolized the empowerment of individuals with disabilities through technology, reinforcing the theme of the event.
        The atmosphere was filled with admiration and inspiration as VIPs, including Dato' Seri Dr. Wan Azizah, applauded the dedication of the students and innovators. The event featured several other assistive technologies and initiatives designed to make science, education, and daily living more accessible to those with disabilities.
        As the event drew to a close, the sentiment of hope and progress lingered in the air. Hari Sains dan OKU was more than just a celebration of science; it was a testament to how innovation can bridge gaps and offer new opportunities for all members of society, regardless of their physical abilities. With efforts like the development of eBrelle, Malaysia continues to take steps toward a more inclusive future, where technology serves as a tool for empowerment.
        <br />
        <br />
        #HelpThemSEE
        <br />
        <br />
        Interested to contribute? Leave us a message at our email address <i>ebrelle.laptop@gmail.com</i>
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border-comment'>
        <h6>100 views &emsp; 0 comments</h6>
      </div>
      
    </div>
  )
}

export default Post1