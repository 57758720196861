import Home from "./routes/Home";
import {Route, Switch} from "react-router-dom"
import Testimony from "./routes/Testimony"
import OnlineTeaching from "./routes/OnlineTeaching"
import Contact from "./routes/Contact";
import SeeProject from "./routes/SeeProject";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path ="/">
            <Home/>
        </Route>
        <Route path ="/seeproject">
            <SeeProject/>
        </Route>
        <Route path ="/testimony">
            <Testimony/>
        </Route>
        <Route path ="/online">
            <OnlineTeaching/>
        </Route>
        <Route path ="/contact">
            <Contact/>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
