import "./Ebrelleuserdata.css"

import user1 from "../assets/user1.JPG"
import user2 from "../assets/user2.jpg"
import user3 from "../assets/user3.jpg"
import user4 from "../assets/user4.jpg"
import user5 from "../assets/user5.jpg"
import user6 from "../assets/user6.jpg"
import user7 from "../assets/user7.jpg"
import user8 from "../assets/user8.jpg"
import user9 from "../assets/user9.jpg"
import user10 from "../assets/user10.jpg"
import user11 from "../assets/user11.jpg"
import user12 from "../assets/user12.jpg"
import user13 from "../assets/user13.jpg"
import user14 from "../assets/user14.jpg"
import user15 from "../assets/user15.jpg"
import user16 from "../assets/user16.jpg"
import user17 from "../assets/user17.JPG"
import user18 from "../assets/user18.jpg"
import user19 from "../assets/user19.jpg"
import user20 from "../assets/user20.jpg"
import user21 from "../assets/user21.jpg"
import user22 from "../assets/user22.jpg"
import user23 from "../assets/putra1.jpeg"
import user24 from "../assets/putra2.jpeg"
import user25 from "../assets/putra3.jpeg"
import user26 from "../assets/putra4.jpeg"
import user27 from "../assets/putra5.jpeg"
import user28 from "../assets/putra6.jpeg"

import user29 from "../assets/usim_1.JPG"
import user30 from "../assets/tpm_2.jpg"
import user31 from "../assets/unipsas_1.jpeg"
import user32 from "../assets/unipsas_2.jpeg"






export const Ebrelleuserdata = () => {
  return (
    <div className="ebrelle-user">
        <div>
            <h1>eBrelle Lense</h1>
        </div>
        <div className="img-user">
            <img src={user31} alt="vice cancelor unipsas show to sultan pahang"/> 
            <img src={user32} alt="sultan pahan touch the ebrelle dots"/> 
            <img src={user23} alt="explained to ksu kesuma"/> 
            <img src={user26} alt="explained to tpm 2"/> 
            <img src={user17} alt="exhibition with pmx wife"/>
            <img src={user30} alt="explained to tpm 1"/> 
            <img src={user16} alt="exhibition with yb fared johor"/>
            <img src={user29} alt="explain to pmx wife"/>
            
            <img src={user24} alt="explained to ksu kesuma"/> 
            <img src={user28} alt="explained to sister raja perlis"/> 
            <img src={user25} alt="explained to reporter"/> 
            
            <img src={user27} alt="explained to ksu kesuma"/> 
            
            
            
            <img src={user18} alt="explained to the user"/>
            <img src={user19} alt="explained to the user"/>

            <img src={user1} alt="explain to teachers"/>
            <img src={user2} alt="training at ssb sarawak"/>
            <img src={user3} alt="training at ec school"/>
            <img src={user4} alt="visitor test ebrelle"/>
            
            <img src={user13} alt="exhibition in germany"/>
            <img src={user14} alt="exhibition at unipsas"/>
            <img src={user15} alt="exhibition at ec school"/>
            
            <img src={user20} alt="workshop on braille quran"/>

            <img src={user21} alt="trainign at pes school"/>
            <img src={user22} alt="exhibition at melaka"/>    
            <img src={user6} alt="training at pes school"/>

            <img src={user7} alt="explained to the user"/>
            <img src={user8} alt="explained to the user"/>
            <img src={user9} alt="explained to the lecturer"/>
            <img src={user10} alt="explained to the visitor"/>

            <img src={user11} alt="explained to the oversea user"/>
            <img src={user12} alt="explained to the oversea user"/> 
            
           

        </div>
    </div>
  )
}
