import "./Footer.css";


export const Footer = () => {
  return (
    <div className="footer">
        <div className="top">
            <div className="laptop-logo">
                <h1>eBrelle <i><sub>Laptop for the blind</sub></i></h1>
            </div>
            <div>
                <a href="https://www.facebook.com/profile.php?id=61560617994497" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-facebook-square fa-beat"></i>
                </a>
                <a href="https://www.tiktok.com/@ebrelle_laptop4blind?is_from_webapp=1&sender_device=pc" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-tiktok fa-beat"></i>
                </a>
                <a href="https://www.youtube.com/@ebraille.malaysia8755" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-youtube fa-bounce"></i>
                </a>
                <a href="https://www.linkedin.com/company/ebraille/" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-linkedin fa-beat-fade"></i>
                </a>
            </div>
        </div>

        <div className="bottom">
            <div className="more-info">
                <h4>More info</h4>
                <h5>These products are available for purchase. We are also seeking partners and collaborators to expand into a wider market. Feel free to drop your emails for any inquiries. Thank you for your interest.</h5>
            </div>
            <div className="manufacture">
                <h4>Manufactured by</h4>
                <h5 href="/"> Edote Sdn. Bhd. (1449861-U) <br/> No 28-2, Jalan Eco Majestic 3A/6B, 43500 Semenyih, Selangor.</h5>
            </div>
            <div className="more-info">
                <h4>Email</h4>
                <h5>ebrelle.laptop@gmail.com</h5>
            </div>
            <div className="manufacture">
                <h4>Contact Number</h4>
                <h5> +60182574520</h5>
            </div>
            
        </div>
        
    </div>
  )
}
