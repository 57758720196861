import NavbarAdvance from '../components/NavbarAdvance'
import { Footer } from "../components/Footer";
import { Testimonydata } from "../components/Testimonydata";

import testimoni1 from "../assets/testimoni1.jpg";
import testimoni2 from "../assets/testimoni2.jpg";
import testimoni3 from "../assets/testimoni3.jpg";
import testimoni4 from "../assets/testimoni4.jpg";
import testimoni5 from "../assets/eBrelle Affan.jpg";
import { Ebrelleuserdata } from "../components/Ebrelleuserdata";

function Testimony() {
  return (
    <div>
      <NavbarAdvance/>
      <Testimonydata
        heading="PHD Student"
        text='eBrelle has made my task of reading and 
        writing for my PHD studies easier, explained 
        Ilham to the VIPs at the Hari Sains 
        dan OKU event at Pusat Sains Negara.'
        author="(Ilham, PHD Student)"
        img1={testimoni1}
        className="first-test"
      />
      <Testimonydata
        heading="College Student"
        text="It’s very easy to take notes in the class now. 
        I have submitted my assignments with ease using the
         eBrelle Notetaker function"
        author="(Qayyim a student from Kelantan)"
        img1={testimoni2}
        className="first-test-reverse"
      />
      <Testimonydata
        heading="School Student"
        text="Thank you eBrelle! Now I don’t have to carry
         my heavy brailler and large textbooks 
         anymore” a student from English College 
         commented upon using the eBrelle."
        author="(Lisha, Student of EC)"
        img1={testimoni3}
        className="first-test-third"
      />
      <Testimonydata
        heading="University Student"
        text="I answered my exams and quizzes smoothly 
        because I can submit my answers in softcopy. 
        If I need to submit in hardcopy, I’ll just 
        have to connect my eBrelle to the printer 
        wirelessly.” Iman happily shows how he sat for 
        his exams at his university"
        author="(Iman, UMS Student)"
        img1={testimoni4}
        className="first-test-reverse"
      />
      <Testimonydata
        heading="School Children"
        text="It’s so fun to use eBrelle! I love to 
        read the storybooks in the eBrelle 
        module,” Affan was excited to try the eBrelle."
        author="(Affan, School Children)"
        img1={testimoni5}
        className="first-test-third"
      />
      
      <Ebrelleuserdata/>

      <Footer />
    </div>
  )
}

export default Testimony