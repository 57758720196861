import { useState } from "react"
import "./NavbarAdvance.css"

function NavbarAdvance() {
  const [state, setState] = useState(false);

  const menuClick = () =>{
    setState(!state);
  }
  return (
    <nav className="nav-container">
        <div className="navbar-title">
            <h1>eBrelle <i><sub>Laptop for the blind</sub></i></h1>
        </div>
        <div className="menu-icons" onClick={menuClick}>
            <i 
                className = {state? "fas fa-times": "fas fa-bars"}>
            </i>
        </div>
        <ul className = {state? "nav-menu active": "nav-menu"}>
            <li>
                <a className="nav-links" href="/">
                    <i className="fa-solid fa-house-user"></i>
                    Home
                </a>
            </li>
            <li>
                <a className="nav-links" href="/seeproject">
                    <i className="fa-solid fa-hand-holding-dollar"></i>
                    SEE Project
                </a>
            </li>
            <li>
                
                <a className="nav-links" href="/testimony">
                    <i className="fa-solid fa-circle-info"></i>
                    Testimony
                </a>
            </li>
            
            
            <li>
                <a className="nav-links" href="/online">
                    <i className="fa-solid fa-chalkboard"></i>
                    Online Teaching
                </a>
            </li>
            <li>
                <a className="nav-links" href="/contact">
                    <i className="fa-solid fa-address-book"></i>
                    Contact Us
                </a>
            </li>
        </ul>

    </nav>
  )
}

export default NavbarAdvance