import React, { useEffect, useRef, useState } from 'react'
import "./SEEPost.css"

import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1 from "../assets/usim_psn.jpg";
import post2 from "../assets/Newsletter 3.jpeg";
import post3 from "../assets/Newsletter 6.jpg";
import post4 from "../assets/Newsletter 9.jpeg";
import post5 from "../assets/eBrelle Homepage 8.jpg";
import post6 from "../assets/Revolutionary 1.jpg";
import post7 from "../assets/Issues 1.png";
import post8 from "../assets/How eBrelle 1d.PNG";
import post9 from "../assets/eBrelle CSR 2.jpg";

import Post1 from './Post1';
import Post2 from './Post2';
import Post3 from './Post3';
import Post4 from './Post4';
import Post5 from './Post5';
import Post6 from './Post6';
import Post7 from './Post7';
import Post8 from './Post8';
import Post9 from './Post9';

  
export const SEEPost = () => {
  const [post, setPost] = useState("0")
  const blackboardsRef = useRef(null);

  
  const menuClick0 = () =>{
    setPost("0");
  }
  const menuClick1 = () =>{
    setPost("1");
  }
  const menuClick2 = () =>{
    setPost("2");
  }
  const menuClick3 = () =>{
    setPost("3");
  }
  const menuClick4 = () =>{
    setPost("4");
  }
  const menuClick5 = () =>{
    setPost("5");
  }
  const menuClick6 = () =>{
    setPost("6");
  }
  const menuClick7 = () =>{
    setPost("7");
  }
  const menuClick8 = () =>{
    setPost("8");
  }
  const menuClick9 = () =>{
    setPost("9");
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [post]);

  return (
    <div className="from-container">
        <br />
        <h1>SEE Project – Strengthening Equal Education for the Blind through eBrelle Technology </h1>
        <br />
        <div onClick={menuClick0} className='all-post'>
          {
            post === "0" ? 
            <h2 >All Posts</h2> :
            <>
            <h2 >All Posts</h2>
            <i class="fa-solid fa-share"></i>
            </>
          }
        </div>
        {
          post === "0" ? 
          <>
            <h6 className="meet-news-first">SEE Project</h6>
            <div className='post-section'>
              <div className='post-image'>
                <img src={post5} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>14 October 2024  (2 min read)</p></h4>
                </div>    
                <div onClick={menuClick5} className='no-line'>
                  <h3>SEE Project: Strengthening Equal Education for the Blind through eBrelle Technology</h3>
                  <h5>
                    The SEE Project is committed to empower visually impaired students across Malaysia 
                    by providing them with access to cutting-edge eBrelle technology....
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>150 views &emsp; 0 comments</h6>
                </div>
              </div> 
            </div>
            <div className='post-section'>
              <div className='post-image'>
                <img src={post6} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>17 October 2024  (2 min read)</p></h4>
                </div>    
                <div onClick={menuClick6} className='no-line'>
                  <h3>eBrelle: The Revolutionary Braille Laptop for the Blind</h3>
                  <h5 className='title-bold'>What is eBrelle?</h5>
                  <h5>
                    eBrelle, a cutting-edge innovation, offers an accessible digital 
                    solution for the blind and visually impaired. As a unique Braille laptop....
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>90 views &emsp; 0 comments</h6>
                </div>
              </div> 
            </div>
            <div className='post-section'>
              <div className='post-image'>
                <img src={post7} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>21 October 2024  (4 min read)</p></h4>
                </div>    
                <div onClick={menuClick7} className='no-line'>
                  <h3>Issues Concerning Visually Impaired Students in Malaysia</h3>
                  <h5 className='title-bold'>Outdated Technology</h5>
                  <h5>
                    In Malaysia, visually impaired students face significant challenges 
                    in accessing quality education, leading to a wide disparity in educational 
                    outcomes compared....
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>90 views &emsp; 0 comments</h6>
                </div>
              </div> 
            </div>
            <div className='post-section'>
              <div className='post-image'>
                <img src={post8} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>24 October 2024  (5 min read)</p></h4>
                </div>    
                <div onClick={menuClick8} className='no-line'>
                  <h3>How eBrelle Technology Addresses the Challenges Faced by Visually Impaired Students</h3>
                  <h5>
                    The eBrelle technology offers a transformative solution to many of the educational 
                    challenges faced by visually impaired students in Malaysia....
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>90 views &emsp; 0 comments</h6>
                </div>
              </div> 
            </div>
            <div className='post-section'>
              <div className='post-image'>
                <img src={post9} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>27 October 2024  (7 min read)</p></h4>
                </div>    
                <div onClick={menuClick9} className='no-line'>
                  <h3>
                    Corporate Social Responsibility: Transforming the Future of Visually Impaired Children 
                    in Malaysia Through eBrelle Technology
                  </h3>
                  <h5>
                    The SEE. Project is aimed at Strengthening Equal Education for the Blind initiative 
                    through eBrelle...
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>90 views &emsp; 0 comments</h6>
                </div>
              </div> 
            </div>
            <h6 className="meet-news">Newsletter</h6>
            <div className='post-section'>
              <div className='post-image' onClick={menuClick1}>
                <img src={post1} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>2 October 2024  (6 min read)</p></h4>
                </div> 
                <div onClick={menuClick1} className='no-line'>  
                  <h3>Hari Sains dan OKU: A Celebration of Innovation and Inclusivity at Pusat Sains Negara</h3>
                  <h5>The vibrant halls of Pusat Sains Negara were filled with excitement and a spirit of 
                  inclusivity during the Hari Sains dan OKU event, where science and technology...
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>100 views &emsp; 0 comments</h6>
                </div>
                
              </div>
            </div>
            <div className='post-section'>
              <div className='post-image' onClick={menuClick2}>
                <img src={post2} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>5 October 2024  (2 min read)</p></h4>
                </div>    
                <div onClick={menuClick2} className='no-line'>
                  <h3>Expo TVET@KKDW 2024: Showcasing eBrelle Innovation at MITC Melaka</h3>
                  <h5>Melaka, 2024 – The TVET@KKDW 2024 Expo, held at the Melaka International 
                    Trade Centre (MITC), garnered significant attention for its focus on 
                    empowering....
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>90 views &emsp; 0 comments</h6>
                </div>
              </div> 
            </div>
            <div className='post-section'>
              <div className='post-image'>
                <img src={post3} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>8 October 2024  (3 min read)</p></h4>
                </div>    
                <div onClick={menuClick3} className='no-line'>
                  <h3>Event Overview: Al-Quran eBrelle Review Part 2</h3>
                  <h5>The second installment of the "Al-Quran eBrelle Review" 
                    workshop was held at the Everly Hotel in Putrajaya. 
                    This three-day, two-night event took place from September 14-16, 2024, 
                    and was organized by...
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>76 views &emsp; 0 comments</h6>
                </div>
              </div> 
            </div>
            <div className='post-section'>
              <div className='post-image'>
                <img src={post4} alt="usim at pusat sains negara"></img>
              </div>
              <div className='post-text'>
                <div className='see-section'>
                  <img src={icon_ebrelle} alt="" />
                  <h4><span>eBrelle</span> <br /> <p>11 October 2024  (2 min read)</p></h4>
                </div>    
                <div onClick={menuClick4} className='no-line'>
                  <h3>eBrelle Laptop for the Blind: A New Exhibition in Pusat Sains Negara</h3>
                  <h5>Pusat Sains Negara recently hosted a significant event where the Vice Chancellor 
                    of Universiti Sains Islam Malaysia (USIM) officially handed over the eBrelle Laptop 
                    for the Blind to be part of the science...
                  </h5>
                </div>
                <div className='see-border'>
                  <h6>106 views &emsp; 0 comments</h6>
                </div>
              </div> 
            </div>
            
          </>

          :
          post === "1" ? 
          <Post1/> : 
          post === "2" ? 
          <Post2/> : 
          post === "3" ? 
          <Post3/> : 
          post === "4" ? 
          <Post4/> : 
          post === "5" ? 
          <Post5/> : 
          post === "6" ? 
          <Post6/> : 
          post === "7" ? 
          <Post7/> : 
          post === "8" ? 
          <Post8/> : 
          post === "9" ? 
          <Post9/> : ""
        } 
    </div>
  )
}
