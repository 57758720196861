import React from 'react'
import "./Post7.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/Issues 1.png";
import post1b from "../assets/user16.jpg";
import post1c from "../assets/eBrelle Issues 3.JPG";
import post1d from "../assets/eBrelle Issues 4.jpg";
import sign from "../assets/afifah_sign.png"


function Post7() {
  return (
    <div className='post1-section'>
      <div className='comment-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>21 October 2024  (4 min read)</p></h4>
      </div>
      <div className='picture7a-section'>
          <img src={post1a} alt="picture ebrelle features"></img>
      </div>
      <div className='text-section'>
        <h3>Issues Concerning Visually Impaired Students in Malaysia</h3>
        <h5><i>Outdated Technology</i></h5>
        
        <h5>
          In Malaysia, visually impaired students face significant challenges 
          in accessing quality education, leading to a wide disparity in educational 
          outcomes compared to their sighted peers. One of the primary barriers is 
          the lack of access to modern assistive technology. While sighted students 
          have ample resources and tools for learning, visually impaired students 
          often rely on outdated equipment like the Perkins Brailler, which is not 
          only cumbersome (the weight is 5KG and students must carry them around from 
          their classroom to their dormitories and vice versa) and limited in functionality 
          but also fails to provide digital access to the vast array of educational resources 
          available online.
        </h5>
      </div> 
      <div className='picture7a-section'>
          <img src={post1b} alt="usim at pusat sains negara"></img>
      </div>
      <div className='text-section'>
        <h5><i>Financial Barriers</i></h5>
        <h5>
          Financial barriers further compound the issue. Modern assistive technologies 
          that can greatly improve learning outcomes, such as Braille laptops and other 
          digital tools, are prohibitively expensive (usually around RM9,000 and above). 
          Many visually impaired students come from low-income (B40, M40) families who cannot 
          afford such devices effectively limiting their access to the learning materials and 
          support they need to thrive academically.
        </h5>
        <h5><i>Lack of Braille Materials</i></h5>
        <h5>
          The limited availability of reading material in accessible formats, 
          such as Braille or audio, means that visually impaired students often have far 
          fewer resources than their sighted peers, placing them at an educational 
          disadvantage. On average, only 1% of printed materials are translated into 
          braille format. However, the case is much worse in Malaysia in which some 
          visually impaired school students in Malaysia only have their braille textbooks 
          as their only source of reading materials
        </h5>
      </div>
      <div className='picture7b-section'>
          <img src={post1c} alt="usim at pusat sains negara"></img>
          <h6>https://www.thestar.com.my/lifestyle/culture/2021</h6>
      </div>
      <div className='text-section'>
        <h5><i>Emotional Burden</i></h5>
        <h5>
          Adding to these challenges is the emotional burden of isolation. 
          Many visually impaired students in Malaysia attend specialized schools 
          that is far from their families, leading to a lack of emotional support 
          during critical developmental years. There are only 12 schools in Malaysia 
          that cater for special education needs for these visually impaired students 
          such as, in Pulau Pinang, Kuala Lumpur, Johor, Sabah and Sarawak. 
          This separation can lead to feelings of loneliness and hinder their academic 
          motivation, as they are unable to rely on the close, day-to-day encouragement 
          and support of their loved ones.
        </h5>
      </div>
      
      
      <div className='picture7b-section'>
          <img src={post1d} alt="usim at pusat sains negara"></img>
          <h6>Students age 7 to 17 from all across Malaysia are placed in Princess Elizabeth School Johor Bahru</h6>
      </div>
      <div className='text-section'>
        <h5><i>Conclusion</i></h5>
        <h5>
          The Strengthening Equal Education (SEE) Project aims to address these 
          issues by introducing eBrelle technology to bridge the educational and 
          emotional gaps faced by visually impaired students in Malaysia. By equipping 
          students with modern Braille technology, this project seeks to provide 
          them with the tools, resources, and support they need to succeed in an 
          increasingly digital and interconnected world.
        </h5>
      </div>
      <div className='text2-section'>
        <h5>
        #HelpThemSEE
        <br />
        <br />
        Interested to contribute? Leave us a message at our email address <i>ebrelle.laptop@gmail.com</i>
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border7-comment'>
        <h6>106 views &emsp; 0 comments</h6>
      </div>
    </div>
  )
}

export default Post7