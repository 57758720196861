import React from 'react'
import "./Post8.css"
import icon_ebrelle from "../assets/eBrelle Homepage 8.jpg"
import post1a from "../assets/How eBrelle 1b.png";
import post1b from "../assets/How eBrelle 2.jpg";
import post1c from "../assets/How eBrelle 3.jpg";
import post1d from "../assets/How eBrelle 4.jpg";
import sign from "../assets/afifah_sign.png"


function Post8() {
  return (
    <div className='post1-section'>
      <div className='comment-section'>
        <img src={icon_ebrelle} alt="" />
        <h4><span>eBrelle</span> <br /> <p>24 October 2024  (5 min read)</p></h4>
      </div> 
      <div className='text-section'>
        <h3>How eBrelle Technology Addresses the Challenges Faced by Visually Impaired Students</h3>
        <h5>
          The eBrelle technology offers a transformative solution to many 
          of the educational challenges faced by visually impaired students 
          in Malaysia. By providing advanced features that support accessible 
          learning and emotional well-being, eBrelle brings visually impaired 
          students closer to equal education opportunities. Here’s how eBrelle 
          tackles each of these major challenges:
        </h5>
        <h5><i>1. Improved Access to Technology</i></h5>
        <h5>
          Traditional tools like the Perkins Brailler have limited functionality, 
          often restricting students to physical Braille writing without access 
          to digital resources. eBrelle replaces these outdated devices with a 
          comprehensive Braille laptop that allows students to read and write in 
          Braille digitally. By translating text from various digital formats—such 
          as PDFs, Word documents, and PowerPoint presentations—eBrelle provides 
          visually impaired students with access to the same digital materials available 
          to sighted students. This automatic text-to-Braille conversion broadens their 
          exposure to educational content and provides them with the flexibility to learn 
          and engage with modern technology.
        </h5>
        <div className='picture8-section'>
          <img src={post1a} alt="usim at pusat sains negara"></img>
          <h6>Teachers and parents can copy and paste any materials to the website – the materials will be sent to the student’s eBrelle in braille format</h6>
        </div>
        <h5><i>2. Affordable Accessibility Solutions</i></h5>
        <h5>
          Financial barriers are a significant hurdle for many families seeking 
          quality education tools for their visually impaired students. While 
          traditional Braille technology tends to be both limited and costly, 
          eBrelle is designed as an all-in-one, budget-friendly solution. With 
          its multifunctional capabilities, it eliminates the need for multiple 
          devices, making it a cost-effective choice. Schools and organizations 
          supporting visually impaired students can also adopt eBrelle to offer 
          shared resources, reducing the financial burden on individual families 
          and making advanced educational tools more accessible.
        </h5>
        <h5><i>3. Expanded Reading Materials in Braille</i></h5>
        <h5>
          A major barrier for visually impaired students is the scarcity 
          of reading materials in accessible formats. eBrelle addresses 
          this issue through its automatic Braille translation capability, 
          enabling students to access a wide range of documents and books 
          independently. Whether it’s school textbooks, reference materials, 
          or leisure reading, students can engage with diverse content without 
          relying on pre-transcribed Braille texts.
        </h5>
        <div className='picture8b-section'>
          <img src={post1b} alt="usim at pusat sains negara"></img>
          <h6>Children books are easily transcribed to braille using eBrelle [markassikecil.com.my]</h6>
        </div>
        <h5><i>4. Empowering Emotional Support and Independence</i></h5>
        <h5>
          The emotional impact of being separated from family and support 
          networks can be isolating for visually impaired students. eBrelle 
          fosters a sense of independence, giving students the tools to manage 
          their own learning and take control of their education. Its easy-to-use 
          notetaking and storage features (with 64GB of storage) allow students to 
          organize their studies and personal notes without relying on external assistance. 
          This empowerment fosters confidence and resilience, helping them cope better with 
          the emotional distance from their families.
        </h5>
        <div className='picture8-section'>
          <img src={post1c} alt="usim at pusat sains negara"></img>
          <h6>Kids with disabilities needs more emotional support from their family [etsy.com]</h6>
        </div>
        <h5><i>5. Inclusive and Modern Learning Environment</i></h5>
        <h5>
          With eBrelle, visually impaired students can participate more fully 
          in a modern learning environment. The technology allows for greater 
          integration in classrooms, where digital tools are the norm, reducing 
          the feeling of being "left out" compared to their sighted classmates. 
          eBrelle encourages inclusivity by bridging the gap between traditional 
          Braille learning and modern digital education, enabling visually impaired 
          students to feel more connected to their school community and more engaged 
          in the learning process.
        </h5>
        <div className='picture8-section'>
          <img src={post1d} alt="usim at pusat sains negara"></img>
          <h6>English College utilizes modern eBrelle technology in their classroom for visually impaired students</h6>
        </div>

        <h5><i>Conclusion</i></h5>
        <h5>
          The SEE Project through eBrelle technology aims to create a more 
          inclusive educational landscape for visually impaired students in 
          Malaysia. By providing accessible, affordable, and versatile tools 
          for learning, eBrelle tackles both the practical and emotional challenges 
          these students face, helping them achieve their full academic potential 
          and promoting equal education for all. Through initiatives like this, eBrelle 
          is not only enhancing access to education but also empowering visually impaired 
          students to build confidence, independence, and a brighter future.
        </h5>
      </div>
      
      <div className='text2-section'>
        <h5>
        #HelpThemSEE
        <br />
        <br />
        Interested to contribute? Leave us a message at our email address <i>ebrelle.laptop@gmail.com</i>
        <br />
        </h5>
      </div>
      <div className='sign-director'>
        <img src={sign} alt="" />
      </div>
      <div className='border8-comment'>
        <h6>106 views &emsp; 0 comments</h6>
      </div>
    </div>
  )
}

export default Post8